import domready from "domready"

/* dismissable banner */
(function (window) {

  var Storage = function () {

    function browserHasSupport() {
      var testItem = 'localStorageTest';
      if (window.localStorage) {
        try {
          window.localStorage.setItem(testItem, testItem);
          window.localStorage.removeItem(testItem);
          return true;
        } catch (e) {
          return false;
        }
      }
    }

    function deleteItem(item) {
      return window.localStorage.removeItem(item);
    }

    function getItem(item) {
      return window.localStorage.getItem(item);
    }

    function setItem(item, contents) {
      return window.localStorage.setItem(item, contents);
    }

    return {
      delete: deleteItem,
      enabled: browserHasSupport,
      get: getItem,
      set: setItem
    };

  };

  window.Storage = new Storage();

})(window);

(function (window, document, Storage) {
  domready(function () {

    var storageEnabled = Storage.enabled();
    var banner = document.querySelector('.banner')
    var expiry, id;
    if (banner) {
      expiry = banner.getAttribute('data-expiry');
      id = banner.getAttribute('data-id');
    }

    let hasExpiry = !!(id && expiry);

    if (hasExpiry && storageEnabled) {
      let timestamp = Storage.get(id);
      if (timestamp) {
        if (expiry === 'forever') {
          return;
        } else {
          var now = new Date(),
            diffInHours = Math.floor((now.getTime() - parseInt(timestamp)) / (1000 * 60 * 60));
          if (diffInHours >= expiry) {
            // show the
            Storage.delete(id);
            banner.style.display = 'block';
          } else {
            return;
          }
        }
      } else {
        // show the banner
        banner.style.display = 'block';
      }
    }

    if (banner) {
      banner.querySelector('.x').addEventListener('click', dismissBanner)
    }

    function dismissBanner(event) {
      event.preventDefault()
      banner.style.display = 'none';
      if (hasExpiry && storageEnabled) {
        Storage.set(id, new Date().getTime());
      }
    }
  })
})(window, document, Storage);
